import React from "react"
import SEO from "components/Seo"
import classNames from "classnames/bind"
import PageHeader from "components/PageHeader"
import styles from "./location.module.css"
import Waves from "images/waves-icon.svg"
import Directions from "images/directions.svg"
import GMap from "components/GMap"
const cx = classNames.bind(styles)

const LocationPage = ({ data }) => (
  <>
    <SEO title={data.sanityLocation.title} />
    <div className={cx("wrapper")}>
      <div className={cx("left-side")}>
        <Waves className={cx("content-icon")} />
        <div className={cx("content")}>
          <div className={cx("coordinates")}>
            {data.sanityLocation?.coordinates}
          </div>
          <PageHeader className={cx("header")}>
            {data.sanityLocation?.title}
          </PageHeader>

          <a
            href={data.sanityGlobalSettings?.maplink}
            target="_blank"
            rel="noreferrer"
            className={cx("action")}
          >
            Nuoroda
          </a>
          <a
            href={data.sanityGlobalSettings?.maplink}
            target="_blank"
            rel="noreferrer"
            className={cx("directions")}
          >
            <Directions className={cx("directions-icon")} />
          </a>
        </div>
      </div>
      <div className={cx("right-side")}>
        <div className={cx("map")}>
          <GMap
            coords={data.sanityLocation?.coordinates}
            title={data.sanityLocation?.title}
          />
        </div>
      </div>
    </div>
  </>
)

export const query = graphql`
  {
    sanityGlobalSettings {
      email
      phone
      maplink
    }

    sanityLocation {
      coordinates
      title
    }
  }
`

export default LocationPage
