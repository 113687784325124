import React from "react"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox"
import theme from "./theme"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
const cx = classNames.bind(styles)

const key = "AIzaSyCD-VDd8D6TDEdJjE9HfL5rjS-OOT8sJdc"

const Map = withScriptjs(
  withGoogleMap(props => {
    const lat = +props.coords.split(",")[0]
    const lng = +props.coords.split(",")[1]

    return (
      <GoogleMap
        defaultZoom={14}
        defaultOptions={{ styles: theme.styles }}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        <Marker
          position={{ lat: lat, lng: lng }}
          // defaultIcon="images/woods-icon.svg"
          defaultIcon="images/pin.svg"
        >
          <InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
            <div className={cx("box")}>{props.title}</div>
          </InfoBox>
        </Marker>
      </GoogleMap>
    )
  })
)

const Wrapper = props => {
  return (
    <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  )
}

export default Wrapper
